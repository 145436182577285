// next
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import NextLink from 'next/link';
import { ArticleProps } from 'src/@types/article';
// components
import { TextMaxLine } from '../../components';
import Image from '../../components/NextSanityImage';
// utils
import { fDate } from '../../utils/formatTime';

// ----------------------------------------------------------------------

type BlogPostItemMobileProps = {
  article: ArticleProps;
  onSiderbar?: boolean;
  path: string;
};

export default function BlogPostItemMobile({ article, path, onSiderbar }: BlogPostItemMobileProps) {
  const { title, header, date, slug } = article;

  const as = `${path}/${slug.current}`;
  const href = `${path}/[slug]`;

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems={{ xs: 'flex-start', md: 'unset' }}
      sx={{ width: 1 }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          width: 80,
          height: 80,
          flexShrink: 0,
          borderRadius: 1.5,
        }}
      >
        <Image ratio="1/1" alt={title} src={header} layout="intrinsic" width={100} height={100} />
      </Box>
      <Stack spacing={onSiderbar ? 0.5 : 1}>
        <NextLink passHref as={as} href={href}>
          <TextMaxLine variant={onSiderbar ? 'subtitle2' : 'h6'} asLink>
            {title}
          </TextMaxLine>
        </NextLink>

        {date && (
          <Stack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            sx={{ typography: 'caption', color: 'text.disabled' }}
          >
            {fDate(date)}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
